#clem-home {
    background-image: url('../images/sfondo_home.jpg');
    background-size: auto 100%;
    background-position: 50%;
}

#clem-home .menu-item {
    font-family: craft-beer;
    font-size: 40px;
}

#clem-home .menu-item-small {
    font-family: craft-beer;
    font-size: 30px;
}

#clem-home #firm {
    position: fixed;
    top: 10px;
    width: 100%;
    font-size: 10px;
    font-weight: 400;
    color: #CCC
}

#clem-home #menu {
    bottom: 15px;
    position: fixed;
    width: 100%;
    line-height: 30px;
}

#clem-home #chisiamo {
    top: 95px;
    position: fixed;
    width: 100%;
    height: 50px;
    line-height: 50px;
}

#clem-home #eventi {
    top: 150px;
    position: fixed;
    width: 100%;
    height: 50px;
    line-height: 50px;
}

#clem-home #socials {
    position: fixed;
    top: 74%;
    left: 0;
    margin:0;
    height: 40px;
    align-items: center;
    justify-content: center;
}

#clem-home #socials a {
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

#clem-home #languages {
    position: fixed;
    height: 40px;
    top: 35px;
}

#clem-home .language-button {
    height: 40px;
    width: 40px;
    background-size: contain;
    background-position: 50%;
    border: transparent;
    background-color: transparent;
    margin-left: calc(50% - 20px);
}

#clem-home #menu svg {
    margin-top: -10px;
}

div.icon {
    -webkit-animation: action .5s infinite alternate;
    animation: action .5s infinite alternate;
}

@-webkit-keyframes action {
    0% { transform: translateY(-5px); }
    100% { transform: translateY(10px); }
}

@keyframes action {
    0% { transform: translateY(-5); }
    100% { transform: translateY(10px); }
}