@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
	font-family: old-style;
	src: url("fonts/oldStyle.ttf");
}

@font-face {
	font-family: craft-beer;
	src: url("fonts/craft_beer.otf");
}

html, body, #root {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
}

div {
  overflow: hidden;
}

a, a:hover, a:active, a:focus {
  color: inherit;
  text-decoration: none;
}

.clem-menu-back {
	font-size: 30px;
	font-family: craft-beer;
	position: fixed;
	bottom: 0;
	left: 0;
  background-image: url("images/patterns/pattern-black.png");
	background-size: 50px;
	background-repeat: repeat;
}

.clem-menu-back-small {
  font-size: 30px;
	font-family: craft-beer;
	position: fixed;
	bottom: 0;
	left: 0;
  background-image: url("images/patterns/pattern-black.png");
	background-size: 50px;
	background-repeat: repeat;
  line-height: 45px;
}

#capped-image {
  max-height: 300px;
}

#label {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}