.date {
    color: white;
    font-size: 20px;
    font-weight: 500;
}

#event-item #label {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

#event-item #value {
    font-size: 16px;
    font-weight: 300;
}

#event-item #value-big {
    font-size: 25px;
    font-weight: 500;
}

.img-event {
    max-height: 100%;
    width: auto;
}

.can-scroll {
    overflow-y: scroll;
}