#clem-menu {
    background: black;
    font-size: 60px;
    font-family: craft-beer;
    height: calc(100% - 45px);
}

#clem-menu #beverage {
    background-image: url("../images/drinks.jpg");
	background-position: 50%;
	background-size: cover;
}

#clem-menu #foods {
    background-image: url("../images/food.jpg");
	background-position: 50%;
	background-size: cover;
}

#clem-menu .overlay {
    background: rgba(0, 0, 0, 0.75);
}