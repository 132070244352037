#menu-item-dot {
    font-family: craft-beer;
    font-size: 25px;
}

#menu-item-dot > .row.dot{
    background-image: url("../images/rec.png");
	background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    height: 8px;
}