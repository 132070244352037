#clem-item {
    margin-bottom: 40px;
}

#clem-item #section_name {
    font-size: 40px;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    font-family: craft-beer;
    text-align: center;
}

#clem-item .title {
    font-size: 33px;
    font-family: craft-beer;
    line-height: 30px;
    color:burlywood;
}

#clem-item #logo {
    height: 30px;
    width: auto;
}

#clem-item #price-label {
    font-size: 11px;
}

#clem-item #price-value {
    font-size: 25px;
    margin-right: 5px;
    line-height: 35px;
    font-weight: 500;
    height: 100%;
    text-transform: uppercase;
}

#clem-item #prices .rounded-pill {
    background-color: brown;
}

#clem-item #allergeni-title {
    font-size: 13px;
    font-weight: 500;
}

#clem-item .badge-new {
    background: red;
    font-size: 12px;
    width: min-content;
    font-weight: 500;
    padding: 2px 7px 2px 6px;
    display: inline-block;
    color: white
}