.popup {
    z-index: 999999;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0 !important;
    background-color: rgba(0,0,0,0.9);
}

.popup > div {
    width: 100%;
    overflow: hidden;
}

.popup img {
    width: auto;
    height: 100%;
}

.popup .close-icon {
    height: 30px;
    width: 30px;
}

.popup .date {
    font-weight: 500;
    font-size: 25px;
}