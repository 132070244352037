@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
	font-family: old-style;
	src: url(/static/media/oldStyle.70bd43df.ttf);
}

@font-face {
	font-family: craft-beer;
	src: url(/static/media/craft_beer.532efc07.otf);
}

html, body, #root {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
}

div {
  overflow: hidden;
}

a, a:hover, a:active, a:focus {
  color: inherit;
  text-decoration: none;
}

.clem-menu-back {
	font-size: 30px;
	font-family: craft-beer;
	position: fixed;
	bottom: 0;
	left: 0;
  background-image: url(/static/media/pattern-black.088d2094.png);
	background-size: 50px;
	background-repeat: repeat;
}

.clem-menu-back-small {
  font-size: 30px;
	font-family: craft-beer;
	position: fixed;
	bottom: 0;
	left: 0;
  background-image: url(/static/media/pattern-black.088d2094.png);
	background-size: 50px;
	background-repeat: repeat;
  line-height: 45px;
}

#capped-image {
  max-height: 300px;
}

#label {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}
#clem-home {
    background-image: url(/static/media/sfondo_home.b0683ea4.jpg);
    background-size: auto 100%;
    background-position: 50%;
}

#clem-home .menu-item {
    font-family: craft-beer;
    font-size: 40px;
}

#clem-home .menu-item-small {
    font-family: craft-beer;
    font-size: 30px;
}

#clem-home #firm {
    position: fixed;
    top: 10px;
    width: 100%;
    font-size: 10px;
    font-weight: 400;
    color: #CCC
}

#clem-home #menu {
    bottom: 15px;
    position: fixed;
    width: 100%;
    line-height: 30px;
}

#clem-home #chisiamo {
    top: 95px;
    position: fixed;
    width: 100%;
    height: 50px;
    line-height: 50px;
}

#clem-home #eventi {
    top: 150px;
    position: fixed;
    width: 100%;
    height: 50px;
    line-height: 50px;
}

#clem-home #socials {
    position: fixed;
    top: 74%;
    left: 0;
    margin:0;
    height: 40px;
    align-items: center;
    justify-content: center;
}

#clem-home #socials a {
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

#clem-home #languages {
    position: fixed;
    height: 40px;
    top: 35px;
}

#clem-home .language-button {
    height: 40px;
    width: 40px;
    background-size: contain;
    background-position: 50%;
    border: transparent;
    background-color: transparent;
    margin-left: calc(50% - 20px);
}

#clem-home #menu svg {
    margin-top: -10px;
}

div.icon {
    -webkit-animation: action .5s infinite alternate;
    animation: action .5s infinite alternate;
}

@-webkit-keyframes action {
    0% { -webkit-transform: translateY(-5px); transform: translateY(-5px); }
    100% { -webkit-transform: translateY(10px); transform: translateY(10px); }
}

@keyframes action {
    0% { -webkit-transform: translateY(-5); transform: translateY(-5); }
    100% { -webkit-transform: translateY(10px); transform: translateY(10px); }
}
#clem-menu {
    background: black;
    font-size: 60px;
    font-family: craft-beer;
    height: calc(100% - 45px);
}

#clem-menu #beverage {
    background-image: url(/static/media/drinks.0fd3fd93.jpg);
	background-position: 50%;
	background-size: cover;
}

#clem-menu #foods {
    background-image: url(/static/media/food.5ea7178f.jpg);
	background-position: 50%;
	background-size: cover;
}

#clem-menu .overlay {
    background: rgba(0, 0, 0, 0.75);
}
#menu-item-dot {
    font-family: craft-beer;
    font-size: 25px;
}

#menu-item-dot > .row.dot{
    background-image: url(/static/media/rec.9cd1f01e.png);
	background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    height: 8px;
}
#clem-menu-beverage {
	background-image: url(/static/media/pattern-black.088d2094.png);
	background-size: 50px;
	background-repeat: repeat;
	overflow: scroll;
}

#clem-menu-beverage h1{
	font-family: craft-beer;
    font-size: 60px;
}

#clem-item {
    margin-bottom: 40px;
}

#clem-item #section_name {
    font-size: 40px;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    font-family: craft-beer;
    text-align: center;
}

#clem-item .title {
    font-size: 33px;
    font-family: craft-beer;
    line-height: 30px;
    color:burlywood;
}

#clem-item #logo {
    height: 30px;
    width: auto;
}

#clem-item #price-label {
    font-size: 11px;
}

#clem-item #price-value {
    font-size: 25px;
    margin-right: 5px;
    line-height: 35px;
    font-weight: 500;
    height: 100%;
    text-transform: uppercase;
}

#clem-item #prices .rounded-pill {
    background-color: brown;
}

#clem-item #allergeni-title {
    font-size: 13px;
    font-weight: 500;
}

#clem-item .badge-new {
    background: red;
    font-size: 12px;
    width: -webkit-min-content;
    width: min-content;
    font-weight: 500;
    padding: 2px 7px 2px 6px;
    display: inline-block;
    color: white
}
#beer-item #label {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}

#beer-item {
    font-size: 90%;
}


#gin-tonic-item #subtitle {
    text-transform: capitalize;
    font-size: 90%;
}




#beer-bottle-icon {
    height: 35px;
}
#beer #beer-icon-0 {
    height: 20px;
}

#beer #beer-icon-1 {
    height: 27px;
}

#beer #beer-icon-2 {
    height: 32px;
}

#beer #price-value {
    font-size: 20px;
}


.popup {
    z-index: 999999;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0 !important;
    background-color: rgba(0,0,0,0.9);
}

.popup > div {
    width: 100%;
    overflow: hidden;
}

.popup img {
    width: auto;
    height: 100%;
}

.popup .close-icon {
    height: 30px;
    width: 30px;
}

.popup .date {
    font-weight: 500;
    font-size: 25px;
}
.date {
    color: white;
    font-size: 20px;
    font-weight: 500;
}

#event-item #label {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

#event-item #value {
    font-size: 16px;
    font-weight: 300;
}

#event-item #value-big {
    font-size: 25px;
    font-weight: 500;
}

.img-event {
    max-height: 100%;
    width: auto;
}

.can-scroll {
    overflow-y: scroll;
}
