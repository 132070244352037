#beer #beer-icon-0 {
    height: 20px;
}

#beer #beer-icon-1 {
    height: 27px;
}

#beer #beer-icon-2 {
    height: 32px;
}

#beer #price-value {
    font-size: 20px;
}